<wuc-card padding="large" cardStyle="primary">
    <h2>Uw nieuwe wachtwoord</h2>
    <form class="form form--password-popover" [formGroup]="form" role="form" (ngSubmit)="onSubmit()"
        (keydown.enter)="!submitting && !form.invalid ? onSubmit() : null">
        <wuc-form-field e2e="new_password">
            <wuc-form-field-label>Nieuw wachtwoord</wuc-form-field-label>
            <wuc-input-password formControlName="newPassword"></wuc-input-password>
        </wuc-form-field>
        <wuc-form-field e2e="new_password_repeat">
            <wuc-form-field-label>Herhaal wachtwoord</wuc-form-field-label>
            <wuc-input-password formControlName="repeatPassword" [hideHint]="true"></wuc-input-password>
            <wuc-form-error *ngIf="
                    form.errors?.notEqual && form.controls.repeatPassword.touched && form.controls.repeatPassword.dirty
                ">
                Wachtwoord is niet gelijk
            </wuc-form-error>
        </wuc-form-field>
    </form>
    <div class="text-right">
        <wuc-button type="primary" [disabled]="submitting || form.invalid" (buttonClick)="onSubmit()">
            Bevestig
        </wuc-button>
    </div>
</wuc-card>