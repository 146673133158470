import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    WucBalloonModule,
    WucCardModule,
    WucListItemModule,
    WucListModule, WucSkeletonModule,
} from '@inshared/website-ui-components';
import { ActivateHemaAccountComponent } from '../activate-hema-account';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    AuthenticationActionService,
    AuthenticationDataService,
    AuthenticationStatusEnum,
    ErrorInterface,
} from 'outshared-lib';
import { AppRoutesEnum } from '@inshared/shared/core';
import { HemaAccountUpdatePasswordComponent } from '../hema-account-update-password';
import { HemaAccountChangeEmailComponent } from '../hema-account-change-email';
import { HemaAccountConfirmCodeComponent } from '../hema-account-confirm-code';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'ins-activate-hema-page',
    templateUrl: './token-processing-hema-page.component.html',
    styleUrls: ['./token-processing-hema-page.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ActivateHemaAccountComponent,
        HemaAccountUpdatePasswordComponent,
        WucBalloonModule,
        WucListItemModule,
        WucListModule,
        WucSkeletonModule,
        HemaAccountChangeEmailComponent,
        HemaAccountConfirmCodeComponent,
    ],
})
export class TokenProcessingHemaPageComponent implements OnInit, OnDestroy {
    @ViewChild('updatePasswordFlowTemplate', { static: true })
    public updatePasswordFlowTemplate?: TemplateRef<HTMLElement>;

    @ViewChild('changeEmailFlowTemplate', { static: true })
    public changeEmailFlowTemplate?: TemplateRef<HTMLElement>;

    @ViewChild('invalidTokenFlowTemplate', { static: true })
    public invalidTokenFlowTemplate?: TemplateRef<HTMLElement>;

    @ViewChild('loadingTemplate', { static: true })
    public loadingTemplate?: TemplateRef<HTMLElement>;

    public currentTemplate?: TemplateRef<HTMLElement>;
    public errors: ErrorInterface[] = [];
    public appRoutesEnum: typeof AppRoutesEnum = AppRoutesEnum;
    public hasSetEmail: boolean = false;

    private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    private authenticationActionService: AuthenticationActionService = inject(AuthenticationActionService);
    private authenticationDataService: AuthenticationDataService = inject(AuthenticationDataService);
    private router: Router = inject(Router);
    private subscriptions: Subscription = new Subscription();
    private token$: Observable<string> = this.activatedRoute.queryParams.pipe(
        map((queryParam) => this.isValidToken(queryParam['token']) ? queryParam['token'] : this.goToLogin()),
    );
    private titleService: Title = inject(Title);

    public ngOnInit(): void {
        this.subscriptions.add(this.processToken());
        this.subscriptions.add(this.processAuthenticationStatus());
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private processToken(): Subscription {
        return this.token$.subscribe({
            next: (token) => this.checkTokenValidity(token),
        });
    }

    private processAuthenticationStatus(): Subscription {
        return this.authenticationDataService.getStatus$().subscribe((status) => {
            switch (status) {
                case AuthenticationStatusEnum.TemporaryPassword:
                    this.currentTemplate = this.updatePasswordFlowTemplate;
                    this.titleService.setTitle('Geef een wachtwoord op voor uw Verzekeringsmap bij InShared');
                    break;
                case AuthenticationStatusEnum.ChangeEmail:
                    this.currentTemplate = this.changeEmailFlowTemplate;
                    this.hasSetEmail
                        ? this.titleService.setTitle('Controleer uw e-mail')
                        : this.titleService.setTitle('E-mailadres opgeven voor uw 2e Verzekeringsmap');
                    break;
                case AuthenticationStatusEnum.SessionExpired:
                    this.currentTemplate = this.invalidTokenFlowTemplate;
                    break;
                case AuthenticationStatusEnum.LoggedIn:
                    this.goToMyZone();
                    break;
            }
        });
    }

    private checkTokenValidity(loginToken: string): void {
        this.authenticationActionService.login$({ loginToken })
            .subscribe({
                next: () => {},
                error: (errors) => {
                    this.errors = errors;
                },
            });
    }

    private isValidToken(token: string): boolean {
        const tokenRegex = new RegExp('^[A-Za-z0-9-_+.=]{180,}$');
        return tokenRegex.test(token);
    }

    private goToMyZone(): void {
        this.router.navigate([AppRoutesEnum.MyZone]);
    }

    private goToLogin(): void {
        this.router.navigate([AppRoutesEnum.Login]);
    }
}
