import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HemaActivationRoutesEnum } from './shared/enums/hema-activation-routes.enum';
import { TokenProcessingHemaPageComponent } from './token-processing-hema-page';
import { ChangeEmailHemaPageComponent } from './change-email-hema-page';
import { UpdatePasswordHemaPageComponent } from './update-password-hema-page';
import { HideHeaderFooterComponent } from './hide-header-footer';

const routes: Routes = [
    {
        path: '',
        component: HideHeaderFooterComponent,
        children: [
            {
                title: 'Activeer uw nieuwe Verzekeringsmap bij InShared',
                path: HemaActivationRoutesEnum.TokenProcessing,
                component: TokenProcessingHemaPageComponent,
                data: {
                    robots: 'noindex, nofollow',
                }
            },
            {
                title: 'Geef een wachtwoord op voor uw Verzekeringsmap bij InShared',
                path: HemaActivationRoutesEnum.ChangePassword,
                component: UpdatePasswordHemaPageComponent,
                data: {
                    robots: 'noindex, nofollow',
                }
            },
            {
                title: 'Activeer uw nieuwe Verzekeringsmap bij InShared',
                path: HemaActivationRoutesEnum.ProvideEmail,
                component: ChangeEmailHemaPageComponent,
                data: {
                    robots: 'noindex, nofollow',
                }
            }
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    exports: [
        RouterModule,
    ],
})
export class HemaActivationRoutingModule {}
