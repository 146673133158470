import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { validateEmail } from '@inshared/shared/util-validators';
import {
    AuthenticationActionService,
    ErrorInterface,
    NewModal,
    NewModalModule,
    LoginTokenTypeCodeEnum,
} from 'outshared-lib';
import { RouterModule } from '@angular/router';
import {
    WucBalloonModule,
    WucButtonModule,
    WucFormErrorModule,
    WucFormFieldLabelModule,
    WucFormFieldModule,
    WucInputTextModule, WucListItemModule, WucListModule,
} from '@inshared/website-ui-components';
import {
    ActivateHemaConfirmationModalComponent,
} from '../activate-hema-confirmation-modal';

@Component({
    selector: 'ins-activate-hema-account',
    templateUrl: './activate-hema-account.component.html',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        ActivateHemaConfirmationModalComponent,
        WucFormFieldModule,
        NewModalModule,
        WucFormFieldLabelModule,
        WucInputTextModule,
        WucFormErrorModule,
        WucButtonModule,
        WucBalloonModule,
        WucListModule,
        WucListItemModule,
    ],
})
export class ActivateHemaAccountComponent {
    @Input() public loginTokenTypeCode!: LoginTokenTypeCodeEnum;
    public loading: boolean = false;
    public errors: ErrorInterface[] = [];

    private authenticationActionService: AuthenticationActionService = inject(AuthenticationActionService);
    private modalService: NewModal = inject(NewModal);

    public credentials: UntypedFormGroup = this.createForm();

    public onSubmit(): void {
        if (this.credentials.invalid) {
            this.credentials.markAllAsTouched();
            return;
        }

        this.loading = true;
        this.errors = [];

        this.authenticationActionService.loginToken$({
            emailAddress: this.credentials.get('email')?.value.trim(),
            emailAddressTypeCode: this.loginTokenTypeCode,
        }).subscribe({
            next: () => this.showConfirmation(),
            error: (errors) => {
                this.errors = errors;
                this.loading = false;
            },
        });
    }

    private showConfirmation(): void {
        this.modalService.open(ActivateHemaConfirmationModalComponent);
        this.loading = false;
    }

    public get email(): AbstractControl {
        return <AbstractControl>this.credentials.get('email');
    }

    public get password(): AbstractControl {
        return <AbstractControl>this.credentials.get('password');
    }

    private createForm(): UntypedFormGroup {
        return new UntypedFormGroup({
            email: new UntypedFormControl('', [Validators.required, validateEmail]),
        });
    }
}
