<section class="section section--content">
    <div class="container">
        <div class="activate-hema-account-box">
            <div class="activate-hema-account__login">
                <h1 class="h2">Activeer uw account</h1>
                <ins-activate-hema-account [loginTokenTypeCode]="loginTokenTypeCodeEnum.Secondary "></ins-activate-hema-account>
            </div>
            <div class="activate-hema-account__extra">
                <h2 class="h3">Vervolgstappen</h2>
                <wuc-list [numbered]="true">
                    <wuc-list-item>Typ uw e-mailadres bij HEMA in</wuc-list-item>
                    <wuc-list-item>Kies een nieuw, sterk wachtwoord</wuc-list-item>
                    <wuc-list-item>Log in met uw e-mailadres en uw nieuwe wachtwoord</wuc-list-item>
                </wuc-list>
            </div>
        </div>
    </div>
</section>

