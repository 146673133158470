<wuc-button type="clean" e2e="modal_close" (buttonClick)="onClose()" modal-close-btn>
    <wuc-icon-ui icon="cross-closed"></wuc-icon-ui>
</wuc-button>

<h2 modal-title>Controleer uw e-mail</h2>
<div modal-content>
    <p>We hebben u een e-mail gestuurd met een unieke link. Via de link kunt u uw inlog account activeren.</p>
    <p>Let op! Uw unieke link is 10 minuten geldig.</p>
</div>
<div modal-actions alignment="end">
    <wuc-button (buttonClick)="onClose()" type="primary" e2e="modal_submit">
        Sluiten
    </wuc-button>
</div>
