import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { WucLinkModule, WucListItemModule, WucListModule } from '@inshared/website-ui-components';
import { ActivateHemaAccountComponent } from '../activate-hema-account/activate-hema-account.component';
import { AppRoutesEnum } from '@inshared/shared/core';
import { LoginTokenTypeCodeEnum } from 'outshared-lib';

@Component({
    selector: 'ins-activate-hema-page',
    templateUrl: './update-password-hema-page.component.html',
    styleUrls: ['./update-password-hema-page.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        WucListModule,
        WucListItemModule,
        WucLinkModule,
        ActivateHemaAccountComponent,
    ],
})
export class UpdatePasswordHemaPageComponent{
    public appRoutesEnum: typeof AppRoutesEnum = AppRoutesEnum;
    public loginTokenTypeCodeEnum: typeof LoginTokenTypeCodeEnum = LoginTokenTypeCodeEnum;
}
