import { NgForOf, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import {
    WucBalloonModule,
    WucButtonModule,
    WucFormErrorModule,
    WucFormFieldLabelModule,
    WucFormFieldModule,
    WucInputPasswordModule, WucInputTextComponent, WucInputTextModule, WucListItemModule, WucListModule,
} from '@inshared/website-ui-components';
import {
    AuthenticationActionService,
    ErrorInterface,
    NewModalModule,
    OutAccountRecoveryModule,
} from 'outshared-lib';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ins-hema-account-confirm-code',
    templateUrl: './hema-account-confirm-code.component.html',
    styleUrls: ['./hema-account-confirm-code.component.scss'],
    standalone: true,
    imports: [
        OutAccountRecoveryModule,
        NewModalModule,
        WucButtonModule,
        ReactiveFormsModule,
        WucFormFieldModule,
        WucFormFieldLabelModule,
        WucInputPasswordModule,
        NgIf,
        WucFormErrorModule,
        WucInputTextModule,
        NgForOf,
        WucBalloonModule,
        WucListItemModule,
        WucListModule,
        FormsModule,
    ],
})
export class HemaAccountConfirmCodeComponent implements OnInit, OnDestroy {
    @ViewChild('digit1Input') digit1Input!: WucInputTextComponent;
    @ViewChild('digit2Input') digit2Input!: WucInputTextComponent;
    @ViewChild('digit3Input') digit3Input!: WucInputTextComponent;
    @ViewChild('digit4Input') digit4Input!: WucInputTextComponent;

    public submitting: boolean = false;
    public errors: ErrorInterface[] = [];
    public notifications: ErrorInterface[] = [];
    private digitValidator = [Validators.pattern('^[0-9]{1}$'), Validators.required];
    public digit1: UntypedFormControl = new UntypedFormControl('', this.digitValidator);
    public digit2: UntypedFormControl = new UntypedFormControl('', this.digitValidator);
    public digit3: UntypedFormControl = new UntypedFormControl('', this.digitValidator);
    public digit4: UntypedFormControl = new UntypedFormControl('', this.digitValidator);

    private subscriptions!: Subscription;
    private authenticationActionService: AuthenticationActionService = inject(AuthenticationActionService);

    public ngOnInit(): void {
        this.subscriptions = new Subscription();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public codeIsValid(): boolean {
        return this.digit1.valid && this.digit2.valid && this.digit3.valid && this.digit4.valid;
    }

    public markAsTouched(): void {
        this.digit1.markAllAsTouched();
        this.digit2.markAllAsTouched();
        this.digit3.markAllAsTouched();
        this.digit4.markAllAsTouched();
    }

    public onKeyDown({
        event,
        nextField,
        previousField,
        formControl,
    }: {
        event: KeyboardEvent;
        nextField?: WucInputTextComponent;
        previousField?: WucInputTextComponent;
        formControl: UntypedFormControl;
    }): void {
        const input = event.target as HTMLInputElement;
        const numericRegex = /^[0-9]*$/;
        const isValidNumber: boolean = numericRegex.test(event.key);
        const isAllowedKey: boolean = ['Backspace', 'Delete', 'Control', 'ArrowLeft', 'ArrowRight'].includes(event.key);
        const isEmpty: boolean = input.value.length === 0;

        if (event.ctrlKey && event.key === 'v') {
            return;
        }

        if (!isAllowedKey) {
            event.preventDefault();
        }

        if (isValidNumber) {
            formControl.setValue(event.key);
        }

        if (isValidNumber && nextField) {
            nextField.setFocus(0);
        }

        if (['Backspace', 'Delete'].includes(event.key) && isEmpty && previousField) {
            previousField.setFocus(0);
        }
    }

    public handlePaste(event: ClipboardEvent): void {
        event.preventDefault();
        const pastedText = event?.clipboardData?.getData('text') as string;
        if (/^\d{4}$/.test(pastedText)) {
            this.digit1.setValue(pastedText[0]);
            this.digit2.setValue(pastedText[1]);
            this.digit3.setValue(pastedText[2]);
            this.digit4.setValue(pastedText[3]);
        }
    }

    public onSubmit(): void {
        if (!this.codeIsValid()) {
            this.markAsTouched();
            return;
        }

        this.errors = [];
        this.submitting = true;

        const code = `${this.digit1.value}${this.digit2.value}${this.digit3.value}${this.digit4.value}`;
        this.validateCode(code);
    }

    private validateCode(code: string): Subscription {
        return this.authenticationActionService.validateEmail$(code).subscribe({
            next: () => this.authenticationActionService.refreshSession(),
            error: (errors: ErrorInterface[]) => {
                this.errors = errors;
                this.submitting = false;
            },
        });
    }
}
