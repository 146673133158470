<section class="section section--content">
    <div class="container">
        <div class="activate-hema-account-box">
            <div class="activate-hema-account__content">
                <h2>Vul de activeringscode in</h2>
                <p>
                    Wij sturen naar het door u opgegeven e-mailadres een mail met uw persoonlijke activeringscode. Sluit
                    dit scherm niet, open uw e-mail en vul hier de 4 cijferige code in die in de e-mail staat.
                </p>
                <p>
                    Let op! De code is 10 minuten geldig.
                </p>
                <form class="form form--password-popover" role="form" (ngSubmit)="$event.preventDefault(); onSubmit()"
                    (keydown.enter)="$event.preventDefault(); !submitting && !codeIsValid ? onSubmit() : null">
                    <wuc-form-field>
                        <wuc-form-field-label>Code: Uw persoonlijke activeringscode</wuc-form-field-label>
                        <wuc-input-text #digit1Input [formControl]="digit1" inputmode="numeric"
                            (keydown)="onKeyDown({event: $event, nextField: digit2Input, formControl:digit1})"
                            (paste)="handlePaste($event)">
                        </wuc-input-text>
                        <wuc-input-text #digit2Input [formControl]="digit2" inputmode="numeric"
                            (keydown)="onKeyDown({event: $event, nextField: digit3Input, previousField: digit1Input, formControl:digit2})"
                            (paste)="handlePaste($event)">
                        </wuc-input-text>
                        <wuc-input-text #digit3Input [formControl]="digit3" inputmode="numeric"
                            (keydown)="onKeyDown({event: $event, previousField: digit2Input, nextField: digit4Input, formControl:digit3})"
                            (paste)="handlePaste($event)">
                        </wuc-input-text>
                        <wuc-input-text #digit4Input [formControl]="digit4" inputmode="numeric"
                            (keydown)="onKeyDown({event: $event, previousField: digit3Input, formControl:digit4})"
                            (paste)="handlePaste($event)">
                        </wuc-input-text>
                    </wuc-form-field>
                    <div class="text-right">
                        <wuc-button type="primary" [isSubmitButton]="true" [disabled]="submitting || !codeIsValid()">
                            Volgende
                        </wuc-button>
                    </div>
                </form>
                <wuc-balloon backgroundStyle="secondary" padding="medium" [hasCloseIcon]="false" *ngIf="errors?.length">
                    <wuc-list icon="cross">
                        <ng-container *ngFor="let error of errors">
                            <wuc-list-item>{{ error.message }}</wuc-list-item>
                        </ng-container>
                    </wuc-list>
                </wuc-balloon>
            </div>
            <div class="activate-hema-account__extra">
                <h2 class="h3">Vervolgstappen</h2>
                <wuc-list [numbered]="true">
                    <wuc-list-item>Vul de code in en klik op "Volgende"</wuc-list-item>
                    <wuc-list-item>Kies een nieuw, sterk wachtwoord</wuc-list-item>
                    <wuc-list-item>Log in met uw e-mailadres en uw nieuwe wachtwoord</wuc-list-item>
                </wuc-list>
            </div>
        </div>
    </div>
</section>