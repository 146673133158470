import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NewModalModule, NewModalRef } from 'outshared-lib';
import { RouterModule } from '@angular/router';
import { WucButtonModule, WucIconUiModule } from '@inshared/website-ui-components';

@Component({
    selector: 'ins-activate-hema-confirmation-modal',
    templateUrl: './activate-hema-confirmation-modal.component.html',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        WucButtonModule,
        WucIconUiModule,
        NewModalModule,
    ],
})
export class ActivateHemaConfirmationModalComponent {
    private modalRef: NewModalRef<ActivateHemaConfirmationModalComponent> = inject(NewModalRef);

    public onClose(): void {
        this.modalRef.close();
    }
}
