import { NgForOf, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, inject, Output, EventEmitter } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import {
    WucBalloonModule,
    WucButtonModule,
    WucFormErrorModule,
    WucFormFieldLabelModule,
    WucFormFieldModule,
    WucInputPasswordModule,
    WucInputTextModule, WucListItemModule, WucListModule,
} from '@inshared/website-ui-components';
import { validateEmail } from '@inshared/shared/util-validators';
import {
    AccountRecoveryActionService,
    EmailTypeCodeEnum,
    ErrorInterface,
    NewModalModule,
    OutAccountRecoveryModule,
} from 'outshared-lib';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ins-hema-account-change-email',
    templateUrl: './hema-account-change-email.component.html',
    styleUrls: ['./hema-account-change-email.component.scss'],
    standalone: true,
    imports: [
        OutAccountRecoveryModule,
        NewModalModule,
        WucButtonModule,
        ReactiveFormsModule,
        WucFormFieldModule,
        WucFormFieldLabelModule,
        WucInputPasswordModule,
        NgIf,
        WucFormErrorModule,
        WucInputTextModule,
        NgForOf,
        WucBalloonModule,
        WucListItemModule,
        WucListModule,
        FormsModule,
    ],
})
export class HemaAccountChangeEmailComponent implements OnInit, OnDestroy {
    @Output() public emailSuccess: EventEmitter<void> = new EventEmitter<void>()

    public submitting: boolean = false;
    public errors: ErrorInterface[] = [];
    public notifications: ErrorInterface[] = [];
    public email: UntypedFormControl = new UntypedFormControl('', [validateEmail, Validators.required]);

    private accountRecoveryActionService: AccountRecoveryActionService = inject(AccountRecoveryActionService);
    private subscriptions!: Subscription;

    public ngOnInit(): void {
        this.subscriptions = new Subscription();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(): void {
        if (!this.email.valid) {
            this.email.markAllAsTouched();
            return;
        }

        this.errors = [];
        this.submitting = true;

        this.updateEmail(this.email.value);
    }


    private updateEmail(emailAddress: string): Subscription {
        return this.accountRecoveryActionService.updateEmail$({
            emailAddress: emailAddress.trim(),
            emailAddressTypeCode: EmailTypeCodeEnum.Primary,
        }).subscribe({
            next: () => this.emailSuccess.emit(),
            error: (errors: ErrorInterface[]) => {
                this.errors = errors;
                this.submitting = false;
            },
        });
    }
}
