<ng-template #updatePasswordFlowTemplate>
    <ins-hema-account-update-password></ins-hema-account-update-password>
</ng-template>

<ng-template #changeEmailFlowTemplate>
    <ins-hema-account-change-email *ngIf="!hasSetEmail" (emailSuccess)="hasSetEmail = true"></ins-hema-account-change-email>
    <ins-hema-account-confirm-code *ngIf="hasSetEmail"></ins-hema-account-confirm-code>
</ng-template>
<ng-template #invalidTokenFlowTemplate>
    <wuc-balloon backgroundStyle="secondary" padding="medium" [hasCloseIcon]="false" *ngIf="errors?.length">
        <wuc-list icon="cross">
            <ng-container *ngFor="let error of errors">
                <wuc-list-item>{{ error.message }}</wuc-list-item>
            </ng-container>
        </wuc-list>
    </wuc-balloon>
</ng-template>
<ng-template #loadingTemplate>
    <wuc-skeleton width="100%" height="50px"></wuc-skeleton>
</ng-template>

<section class="section section--content">
    <div class="container">
        <ng-container [ngTemplateOutlet]="currentTemplate ?? loadingTemplate"></ng-container>
    </div>
</section>
