<section class="section section--content">
    <div class="container">
        <div class="activate-hema-account-box">
            <div class="activate-hema-account__content">
                <h2>Geef een ander, 2e e-mailadres op voor uw nieuwe Verzekeringsmap</h2>
                <p>
                    U bent al klant bij InShared met het e-mailadres dat u ook bij HEMA gebruikte. 2 Verzekeringsmappen
                    met hetzelfde
                    e-mailadres kan helaas niet. Daarom moet u voor uw Verzekeringsmap met de verzekering(en) die van
                    HEMA over zijn
                    genomen, een ander, 2e e-mailadres opgeven.
                </p>
                <form class="form form--password-popover" role="form" (ngSubmit)="$event.preventDefault(); onSubmit();"
                    (keydown.enter)="$event.preventDefault(); !submitting && !email.invalid ? onSubmit() : null">
                    <wuc-form-field e2e="alternative_email">
                        <wuc-form-field-label>Uw andere, 2e e-mailadres</wuc-form-field-label>
                        <wuc-input-text [formControl]="email" id="email" type="email" name="email"
                            placeholder="E-mailadres" autocomplete="username" e2e="email_address"></wuc-input-text>
                    </wuc-form-field>
                    <div class="text-right">
                        <wuc-button type="primary" [isSubmitButton]="true" [disabled]="submitting || email.invalid">
                            Volgende
                        </wuc-button>
                    </div>
                </form>
                <wuc-balloon backgroundStyle="secondary" padding="medium" [hasCloseIcon]="false" *ngIf="errors?.length">
                    <wuc-list icon="cross">
                        <ng-container *ngFor="let error of errors">
                            <wuc-list-item>{{ error.message }}</wuc-list-item>
                        </ng-container>
                    </wuc-list>
                </wuc-balloon>
            </div>
            <div class="activate-hema-account__extra">
                <h2 class="h3">Vervolgstappen</h2>
                <wuc-list [numbered]="true">
                    <wuc-list-item>Typ uw e-mailadres in en klik op "Volgende"</wuc-list-item>
                    <wuc-list-item>U ontvangt een 4 cijferige code op het door u opgegeven mailadres</wuc-list-item>
                    <wuc-list-item>Vul de code in op het volgende scherm</wuc-list-item>
                    <wuc-list-item>Kies een nieuw, sterk wachtwoord</wuc-list-item>
                    <wuc-list-item>Log in met uw e-mailadres en uw nieuwe wachtwoord</wuc-list-item>
                </wuc-list>
                <br />
                <p>
                    <b>Heeft u nog geen ander, 2e e-mailadres?</b>
                    U kunt gratis een e-mailadres aanmaken op bijvoorbeeld gmail.com (Google) of outlook.com
                    (Microsoft). Waarschijnlijk heeft uw internetprovider ook informatie hoe u meerdere e-mailadressen
                    kunt aanmaken.
                </p>
            </div>
        </div>
    </div>
</section>