import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    ReactiveFormsModule,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
    WucButtonModule,
    WucCardModule,
    WucFormErrorModule,
    WucFormFieldLabelModule,
    WucFormFieldModule,
    WucInputPasswordModule, WucInputTextModule, WucListModule,
} from '@inshared/website-ui-components';
import { PasswordStrengthValidator, equalFormControlValidator } from '@inshared/shared/util-validators';
import {
    AccountRecoveryActionService,
    AuthenticationActionService,
    AuthenticationDataService,
    AuthenticationStatusEnum,
    ErrorInterface,
    NewModalModule,
    OutAccountRecoveryModule,
} from 'outshared-lib';
import { Subscription, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppRoutesEnum } from '@inshared/shared/core';

@Component({
    selector: 'ins-hema-account-update-password',
    templateUrl: './hema-account-update-password.component.html',
    standalone: true,
    imports: [
        OutAccountRecoveryModule,
        NewModalModule,
        WucButtonModule,
        ReactiveFormsModule,
        WucFormFieldModule,
        WucFormFieldLabelModule,
        WucInputPasswordModule,
        NgIf,
        WucFormErrorModule,
        WucInputTextModule,
        WucListModule,
        WucCardModule,
    ],
})
export class HemaAccountUpdatePasswordComponent implements OnInit, OnDestroy {
    public submitting: boolean = false;
    public errors: ErrorInterface[] = [];
    public notifications: ErrorInterface[] = [];
    public form: UntypedFormGroup = this.initializeForm();

    private subscriptions!: Subscription;

    public get newPassword(): AbstractControl {
        return this.form.get('newPassword')!;
    }

    public get repeatPassword(): AbstractControl {
        return this.form.get('repeatPassword')!;
    }

    constructor(
        private router: Router,
        private accountRecoveryActionService: AccountRecoveryActionService,
        private authenticationActionService: AuthenticationActionService,
        private authenticationDataService: AuthenticationDataService,
    ) {
    }

    public ngOnInit(): void {
        this.subscriptions = new Subscription();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public onSubmit(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.errors = [];
        this.submitting = true;

        this.updatePassword(this.newPassword.value);
    }

    private initializeForm(): UntypedFormGroup {
        return new UntypedFormGroup(
            {
                newPassword: new UntypedFormControl('', [
                    PasswordStrengthValidator.hasNoWordCharacters(),
                    PasswordStrengthValidator.hasNoNumbers(),
                    PasswordStrengthValidator.isBetween(8, 40),
                    PasswordStrengthValidator.hasInvalidCharacters(),
                ]),
                repeatPassword: new UntypedFormControl('', [Validators.required]),
            },
            { validators: equalFormControlValidator('newPassword', 'repeatPassword') },
        );
    }

    private checkAuthenticationStatus(): Subscription {
        return this.authenticationDataService
            .getStatus$()
            .pipe(filter((status) => status === AuthenticationStatusEnum.LoggedIn))
            .subscribe(() => {
                this.goToMyZone();
            });
    }

    private updatePassword(newPassword: string): Subscription {
        return this.accountRecoveryActionService.updatePassword$(newPassword)
            .pipe(tap(() => this.authenticationActionService.refreshSession()))
            .subscribe({
                next: () => this.subscriptions.add(this.checkAuthenticationStatus()),
                error: (errors: ErrorInterface[]) => {
                    this.errors = errors;
                    this.submitting = false;
                },
            });
    }

    private goToMyZone(): void {
        this.submitting = false;
        this.router.navigate([AppRoutesEnum.MyZone]);
    }
}
