<form [formGroup]="credentials" (ngSubmit)="onSubmit()">
    <wuc-form-field>
        <wuc-form-field-label>Uw e-mailadres bij HEMA:</wuc-form-field-label>
        <wuc-input-text
            id="email"
            type="email"
            name="email"
            formControlName="email"
            placeholder="E-mailadres"
            autocomplete="username"
            e2e="email_address"
        ></wuc-input-text>
        <wuc-form-error *ngIf="email.touched && email.hasError('required')">
            Oeps! Vult u uw e-mailadres nog even in?
        </wuc-form-error>
        <wuc-form-error *ngIf="email.touched && email.hasError('email')">
            Helaas! Het e-mailadres is niet goed ingevuld.
        </wuc-form-error>
    </wuc-form-field>

    <wuc-form-field hasNoState="true">
        <wuc-form-field-label></wuc-form-field-label>
        <wuc-button type="primary" [isSubmitButton]="true" [isFullWidth]="true" [disabled]="loading" e2e="login_submit">
            Volgende
        </wuc-button>
    </wuc-form-field>
</form>

<wuc-balloon backgroundStyle="secondary" padding="medium" [hasCloseIcon]="false" *ngIf="errors?.length">
    <wuc-list icon="cross">
        <ng-container *ngFor="let error of errors">
            <wuc-list-item>{{ error.message }}</wuc-list-item>
        </ng-container>
    </wuc-list>
</wuc-balloon>
