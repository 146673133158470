import { Component, Inject, OnDestroy, OnInit, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
    standalone: true,
    template: `<router-outlet></router-outlet>`,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./hide-header-footer.component.scss'],
    imports: [
        RouterOutlet,
    ]
})
export class HideHeaderFooterComponent implements OnInit, OnDestroy {
    private renderer: Renderer2;

    private readonly className: string = 'hema-activation';

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private rendererFactory: RendererFactory2,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public ngOnInit(): void {
        this.renderer.addClass(this.document.body, this.className);
    }

    public ngOnDestroy(): void {
        this.renderer.removeClass(this.document.body, this.className);
    }


}
